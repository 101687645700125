import {useNavigate} from "react-router-dom";
import {IoIosHammer} from "react-icons/io";
import {IoAccessibility, IoBookmark} from "react-icons/io5";
import {RiJavaFill, RiTailwindCssFill} from "react-icons/ri";
import {SiTypescript} from "react-icons/si";
import {FaGears, FaReact} from "react-icons/fa6";
import {BiLogoSpringBoot} from "react-icons/bi";
import {LuBrainCircuit} from "react-icons/lu";
import {HiOutlineLightBulb} from "react-icons/hi";
import {MdOutlineDocumentScanner} from "react-icons/md";
import {LiaChalkboardTeacherSolid} from "react-icons/lia";
import MainAppRouter from "../../router/MainAppRouter";
import React from "react";
import {GrDatabase, GrDocumentUser} from "react-icons/gr";
import {TbInfoTriangle} from "react-icons/tb";

export default function AiDocumentCheckerPresentation() {

    const documentCheckerFeatures = [
        {
            id: "#p1",
            icon: <GrDocumentUser className={"text-main-500 group-hover:text-white"}/>,
            title: "Pasul 1",
            description: ["Dispecerul încarcă documentul în portalul nostru spre analiză.", "Acceptăm documente importante în scopul validării transportatorilor (asigurări, licențe, certificate de înregistrare, etc.) în următoarele formate: PDF, PNG, JPEG, JPG"],
        },
        {
            id: "#p2",
            icon: <LuBrainCircuit className={"text-main-500 group-hover:text-white"}/>,
            title: "Pasul 2",
            description: ["Documentul este analizat de către rețeaua noastră neurală și trecut prin mai multe filtre de securitate pentru a detecta imperfecțiuni sau manipulări."],
        },
        {
            id: "#p3",
            icon: <TbInfoTriangle className={"text-main-500 group-hover:text-white"}/>,
            title: "Pasul 3",
            description: ["După maxim 30 de secunde, totul este gata! Îți afișăm rezultatele și informațiile extrase din text, iar tu iei decizia dacă colaborezi sau nu."],
        },
        {
            id: "#p4",
            icon: <LiaChalkboardTeacherSolid className={"text-main-500 group-hover:text-white"}/>,
            title: "Pasul 4",
            description: ["Împreună cu informațiile extrase din document, dispecerul este ghidat în timp real în cazul necesității unei verificări mai amănunțite."],
        },
        {
            id: "#p5",
            icon: <GrDatabase className={"text-main-500 group-hover:text-white"}/>,
            title: "Pasul 5",
            description: ["Stocarea documentului, alături de informațiile extrase automat din el are loc în baza noastră de date asociată contului firmei. Dispecerii au acess facil la toate informațiile centralizat."],
        }
    ];

    const technologies = [
        {
            color: "bg-[#FB9B00]",
            icon: <HiOutlineLightBulb className={"scale-150 text-white"}/>,
            text: "Inteligență Artificială"
        },
        {
            color: "bg-[#ffc000]",
            icon: <LuBrainCircuit className={"scale-150 text-white"}/>,
            text: "Rețea Neurală"
        },
        {
            color: "bg-[#8bc34b]",
            icon: <BiLogoSpringBoot className={"scale-150 text-white"}/>,
            text: "Spring Boot"
        },
        {
            color: "bg-[#60d4f6]",
            icon: <FaReact className={"scale-150 text-white"}/>,
            text: "React"
        },
        {
            color: "bg-[#3178c6]",
            icon: <SiTypescript className={"scale-150 text-white"}/>,
            text: "TypeScript"
        },
        {
            color: "bg-[#06b6d4]",
            icon: <RiTailwindCssFill className={"scale-150 text-white"}/>,
            text: "Tailwind CSS"
        },
        {
            color: "bg-[#ff3b00]",
            icon: <RiJavaFill className={"scale-150 text-white"}/>,
            text: "Java"
        },
    ];

    const numbers = [
        {
            display: "~73%",
            category: "acuratețe depistare documente frauduloase"
        },
        {
            display: "<30s",
            category: "secunde pentru analiza documentului"
        },
        {
            display: "+90%",
            category: "sustragere informații documente"
        },
    ]

    return (
        <div className={"bg-main-background min-h-screen"}>
            <section className={"px-10 md:pr-0 md:pl-20 pb-20 pt-[200px] bg-white flex justify-center"}>
                <div className={"flex w-full gap-10"}>
                    <div className={"w-full"}>
                        <h1 className={"font-main-bold text-3xl md:text-6xl pb-1 text-center md:text-start text-main-500"}>
                            DocuChecker
                        </h1>
                        <h1 className={"font-main-bold text-3xl md:text-6xl pb-5 text-center md:text-start"}>
                            Siguranță și încredere în alegerea transportatorilor
                        </h1>
                        <p className={"text-md md:text-xl pb-5 text-center md:text-start"}>
                            Docu Checker vă ajută să reduceți riscurile în transportul rutier de marfă, scanând
                            documentele și ghidând dispecerii în verificările necesare. Automatizăm extragerea și
                            stocarea datelor, oferind un proces optimizat și eficient în alegerea colaboratorilor.
                        </p>
                        <div className={"flex md:justify-start justify-center items-center py-1.5"}>
                            <a href={MainAppRouter.contact}
                               className={"w-fit flex gap-1 justify-center items-center px-8 font-main-bold py-4 bg-main-500 text-white rounded-xl"}>
                                <IoBookmark className={"text-white mb-1"}/>
                                Contacteaza-ne!
                            </a>
                        </div>
                    </div>
                    <div className={"w-full hidden lg:flex justify-center items-center"}>
                        <img src={"img/ai_document_checker_hero_bg_p.svg"}
                             className={"rounded-xl object-contain w-[500px] h-full"} alt={""}/>
                    </div>
                </div>
            </section>
            <section className="py-10 h-fit ">
                <div className={"px-8 relative z-10 md:px-32"}>
                    <p className="text-xl font-semibold text-center w-full pt-5">
                        Statistici pentru <span className={"font-main-bold text-2xl"}>DocuChecker</span>
                    </p>
                    <div className="flex flex-wrap justify-center items-center gap-5 mt-10">
                        {
                            numbers.map((el, index) => (
                                <div key={index}
                                     className="p-3 bg-white border border-main-500 rounded-lg shadow-lg w-full lg:w-[23%] hover:shadow-2xl transition-shadow duration-300">
                                    <h2 className="text-6xl text-center w-full my-3 text-main-500 font-main-bold">{el.display}</h2>
                                    <p className="text-2xl text-center">
                                        {el.category}
                                    </p>
                                </div>
                            ))
                        }
                    </div>
                </div>
            </section>
            <section className={"p-10 flex justify-center"}>
                <div className={"flex flex-col w-full max-w-[1150px] gap-10"}>
                    <h1 className={"text-6xl font-main-bold"}>Beneficiile principale</h1>
                    <p className={"text-2xl"}>
                        Descoperă funcționalitățile cheie ale aplicației noastre, concepute pentru a reduce riscul in
                        alegerea furnizorilor de transport.
                    </p>
                    <div className={"grid grid-cols-1 lg:grid-cols-3 gap-10"}>
                        <div
                            className={"flex flex-col gap-5 bg-white rounded-xl border-main-500 p-7 shadow-lg hover:-translate-y-4 duration-300"}>
                            <MdOutlineDocumentScanner className={"text-main-500 w-14 h-14"}/>
                            <h1 className={"font-main-bold text-4xl mt-2"}>Scanare</h1>
                            <p>
                                Documentele sunt procesate prin <span className={"text-main-500"}>rețeaua neurală</span>,
                                iar dispecerii primesc un punctaj de
                                risc bazat pe diverse analize. Sistemul nostru oferă un ghidaj eficient, ajutând
                                utilizatorii să <span
                                className={"text-main-500"}>identifice potențialele riscuri</span> și să ia decizii
                                informate pentru o
                                gestionare mai sigură a proceselor.
                            </p>
                        </div>
                        <div
                            className={"flex flex-col gap-5 bg-white rounded-xl border-main-500 p-7 shadow-lg hover:-translate-y-4 duration-300"}>
                            <LiaChalkboardTeacherSolid className={"text-main-500 w-14 h-14"}/>
                            <h1 className={"font-main-bold text-4xl"}>Educatie</h1>
                            <p>
                                Aplicația <span className={"text-main-500"}>ghidează dispecerii</span> pas cu pas în
                                procesul de validare a furnizorilor de
                                transport, oferind în același timp <span
                                className={"text-main-500"}>recomandări</span> pentru bune practici din domeniu. Astfel,
                                procesul devine mai eficient și sigur, iar dispecerii sunt sprijiniți să ia <span
                                className={"text-main-500"}>decizii
                                informate</span>.
                            </p>
                        </div>
                        <div
                            className={"flex flex-col gap-5 bg-white rounded-xl border-main-500 p-7 shadow-lg hover:-translate-y-4 duration-300"}>
                            <FaGears className={"text-main-500 w-14 h-14"}/>
                            <h1 className={"font-main-bold text-4xl"}>Automatizare</h1>
                            <p>
                                Produsul nostru <span className={"text-main-500"}>automatizează extragerea și stocarea datelor din documente</span>,
                                economisind
                                timp și reducând erorile. Sistemul procesează rapid informațiile relevante și le
                                organizează pentru acces facil, optimizând astfel <span className={"text-main-500"}>gestionarea datelor</span>.
                            </p>
                        </div>
                    </div>
                </div>
            </section>
            <section className={"py-20 px-10 flex justify-center bg-white"}>
                <div className={"flex flex-col lg:flex-row w-full max-w-[1150px] gap-10"}>
                    <div className={"w-full lg:w-1/2"}>
                        <h1 className={"text-6xl font-main-bold"}>Technologii</h1>
                        <p className={"mt-10 text-justify"}>
                            Produsul nostru utilizează <span className={"text-main-500 font-bold"}>Inteligența Artificială</span> și <span
                            className={"text-main-500 font-bold"}>Rețelele
                            Neurale</span> pentru
                            a analiza și
                            extrage date din documente în timp real, reducând astfel riscurile și îmbunătățind
                            securitatea procesului. Arhitectura sa robustă, bazată pe <span
                            className={"text-main-500 font-bold"}>Java</span> și <span
                            className={"text-main-500 font-bold"}>Spring Boot</span>,
                            împreună cu o
                            interfață modernă creată cu <span className={"text-main-500 font-bold"}>React</span>, <span
                            className={"text-main-500 font-bold"}>TypeScript</span> și <span
                            className={"text-main-500 font-bold"}>Tailwind CSS</span>, asigură
                            o soluție fiabilă și
                            performantă, concepută pentru a răspunde nevoilor complexe ale utilizatorilor.
                        </p>
                    </div>
                    <div className={"h-full w-full lg:w-1/2 flex items-center justify-center flex-wrap gap-4"}>
                        {
                            technologies.map((tech, i) =>
                                <div
                                    className={"flex flex-row justify-center h-fit items-center gap-3 rounded-full border p-4 shadow-lg " + tech.color}>
                                    {tech.icon}
                                    <h1 className={"font-main-medium text-white"}>{tech.text}</h1>
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
            <section className={"p-10 flex justify-center"}>
                <div className={"flex xl:flex-row flex-col w-full max-w-[1150px] gap-10"}>
                    <div className={"w-full h-fit xl:sticky xl:top-[90px]"}>
                        <h1 className={"font-main-bold text-3xl md:text-5xl text-center md:text-start flex gap-2 xl:gap-7 flex-col pb-10"}>
                            <span>
                                Care sunt pasii
                            </span>
                            <span className={"text-main-500 md:text-6xl text-5xl"}>
                                DocuChecker
                            </span>
                        </h1>
                        <div className={"flex justify-center xl:justify-start flex-wrap gap-2 w-full xl:gap-2"}>
                            {
                                documentCheckerFeatures.map((el, i) =>
                                    <a href={el.id}
                                       className={"group flex gap-3 text-2xl py-1.5 px-3 rounded-full hover:bg-main-500 hover:bg-opacity-70 hover:text-white bg-white shadow-md"}>
                                        {el.icon}
                                        <p className={"font-main-medium"} key={i}>Pasul {(i + 1)}</p>
                                    </a>
                                )
                            }
                        </div>
                    </div>
                    <div className={"w-full"}>
                        {
                            documentCheckerFeatures.map((el, i) =>
                                <div id={el.id.replace("#", "")} className={"bg-white rounded-xl shadow-md m-5 p-5"}>
                                    <h2 className={"text-center font-main-medium text-2xl flex gap-3 justify-center items-center mr-6 mb-5"}>
                                        {el.icon}{el.title}
                                    </h2>
                                    {
                                        el.description.map((d, p) =>
                                            <p className={"text-justify text-md"} key={p}>{d}</p>
                                        )
                                    }
                                </div>
                            )
                        }
                    </div>
                </div>
            </section>
        </div>
    );
};