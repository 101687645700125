import {IoIosHammer} from "react-icons/io";
import {IoArrowForward, IoBookmark} from "react-icons/io5";
import MainAppRouter from "../../router/MainAppRouter";
import React from "react";

export default function Home() {

    const numbers = [
        {
            count: 150,
            display: "150+",
            category: "Firme sunt satisfacute"
        },
        {
            count: 30,
            display: "< 30",
            category: "secunde pentru analiza documentului"
        },
        {
            count: 0,
            display: "0",
            category: "Taxe pentru tine"
        }
    ]

    const sectionDetails = [
        {
            id: "#first",
            category: "Category",
            title: "The first Feature we will have",
            description: "All the tools you need in one platform, boosting efficiency, enhancing visibility, and empowering everyone with data-driven decisions.",
            cards: [
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                },
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                },
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                }
            ]
        },
        {
            id: "#second",
            category: "Category",
            title: "The first Feature we will have",
            description: "All the tools you need in one platform, boosting efficiency, enhancing visibility, and empowering everyone with data-driven decisions.",
            cards: [
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "TEST TEST",
                },
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                },
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                }
            ]
        },
        {
            id: "#third",
            category: "Category",
            title: "The first Feature we will have",
            description: "All the tools you need in one platform, boosting efficiency, enhancing visibility, and empowering everyone with data-driven decisions.",
            cards: [
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                },
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                },
                {
                    imagePath: "/img/placeholder_large.jpg",
                    cardsTitle: "Titlu mare care trebuie sa fina aici",
                    cardsDescription: "O descriere cuprinzatoare",
                }
            ]
        }
    ]

    return (
        <div className={"bg-main-background"}>
            <section className="h-screen flex justify-center bg-hero-pattern bg-cover items-center glex-row pt-[100px]">
                <div className={"w-0 md:w-[35%] h-[250px] overflow-hidden"}>
                    <img src={"/camion.svg"} className={"w-full h-full object-cover object-right"} alt={""}/>
                </div>
                <div className="px-20 mb-44 md:mb-32 w-full justify-center items-center flex flex-col pb-10">
                    <img src={"/bec_website.svg"} className={"h-[150px] md:hidden pb-5"} alt={""}/>
                    <h1 className={"w-full text-xl sm:text-4xl md:text-6xl font-main-bold pb-3 text-center"}>
                        Inovație în transporturi prin <span className={"text-main-500"}>inteligență artificială</span>
                    </h1>
                    <h3 className={"text-sm sm:text-lg md:text-xl text-gray-700 text-center"}>
                        INOVIA dezvoltă soluții IT inovatoare pentru automatizarea proceselor din transportul rutier de
                        marfă pentru a reduce riscul și îmbunătăți eficiența în operațiunile zilnice.
                    </h3>
                    <a
                        href={MainAppRouter.portal}
                        className={"w-fit h-fit mt-4 px-5 text-white py-2.5 rounded-2xl flex gap-3 items-center justify-center font-main-bold bg-main-500 shadow-lg"}>
                        Către portal
                        <IoArrowForward/>
                    </a>
                </div>
                <div className={"w-0 md:w-[35%] h-[250px] overflow-hidden"}>
                    <img src={"/camion2.svg"} className={"w-full h-full object-cover object-left"} alt={""}/>
                </div>
            </section>

            <section className="pb-10 h-fit lg:h-[400px]">
                <div className={"absolute z-0 w-full bg-[#ffad33] border-b-2 h-[200px]"}/>
                <div className={"px-8 relative z-10 md:px-32"}>
                    <p className="text-3xl font-main-bold text-white text-center w-full pt-5">
                        Parteneri <span className={"text-4xl"}>INOVIA</span>
                    </p>
                    <div className="flex flex-col lg:flex-row justify-center items-center gap-10 lg:gap-20 mt-10">
                        <div className={"h-[180px] w-full lg:w-[500px] bg-white p-4 rounded-xl shadow-lg"}>
                            <img src={"/img/logo_dacoda.png"} className={"h-full w-full object-contain"}/>
                        </div>
                        <div className={"h-[180px] w-full lg:w-[300px] bg-white p-4 rounded-xl shadow-lg lg:mr-32"}>
                            <img src={"/img/logo_user.png"} className={"h-full w-full object-contain"}/>
                        </div>
                    </div>
                </div>
            </section>

            <section className={"py-10 flex justify-center items-center"}>
                <div className={"flex w-full max-w-[1150px] gap-10"}>
                    <div className={"w-full flex justify-center flex-col"}>
                        <h1 className={"font-main-bold text-3xl md:text-6xl pb-1 text-center md:text-start text-main-500"}>
                            DocuChecker
                        </h1>
                        <h1 className={"font-main-bold text-3xl md:text-5xl pb-5 text-center md:text-start"}>
                            Siguranță și încredere în alegerea transportatorilor
                        </h1>
                        <div className={"flex md:justify-start justify-center items-center py-1.5"}>
                            <a href={MainAppRouter.documentCheckerAi}
                               className={"w-fit flex gap-1 justify-center items-center px-8 font-main-bold py-4 bg-main-500 text-white rounded-xl"}>
                                Află mai multe
                            </a>
                        </div>
                    </div>
                    <div className={"w-full hidden lg:flex justify-end items-center"}>
                        <img src={"img/ai_document_checker_hero_bg_p.svg"}
                             className={"rounded-xl object-contain w-[500px] h-full"} alt={""}/>
                    </div>
                </div>
            </section>

            {/*<section className={"flex flex-col lg:flex-row gap-10 px-8 md:px-32 pb-8"}>*/}
            {/*    <div className={"w-full lg:w-3/4 flex gap-44 flex-col"}>*/}
            {/*        {*/}
            {/*            sectionDetails.map((el, index) =>*/}
            {/*                    <div id={el.id.replace("#", "")} className={"p-2"} key={index}>*/}
            {/*            <span*/}
            {/*                className="bg-main-500 text-white text-xs font-medium me-2 px-2 py-1 rounded-lg">{el.category}</span>*/}
            {/*                        <h1 className={"text-3xl font-main-bold mt-3"}>{el.title}</h1>*/}
            {/*                        <p className={"text-lg text-gray-900"}>{el.description}</p>*/}
            {/*                        <div className="flex flex-wrap justify-start items-center gap-5 mt-10">*/}
            {/*                            {*/}
            {/*                                el.cards.map((card, i) =>*/}
            {/*                                    <div*/}
            {/*                                        key={i}*/}
            {/*                                        className="bg-white border-main-500 border-2 rounded-lg shadow-lg w-full lg:w-1/4 hover:shadow-xl transition-shadow duration-300">*/}
            {/*                                        <div className="bg-main-500 px-4 pt-4 w-full rounded-t-md">*/}
            {/*                                            <img src={card.imagePath}*/}
            {/*                                                 className={"h-48 w-full object-cover rounded-t-md"}*/}
            {/*                                                 alt={""}/>*/}
            {/*                                        </div>*/}
            {/*                                        <h2 className="text-xl text-center px-4 w-full mt-3 text-gray-900 font-main-bold">*/}
            {/*                                            {card.cardsTitle}*/}
            {/*                                        </h2>*/}
            {/*                                        <p className="text-base text-gray-600 px-4 pb-4">*/}
            {/*                                            {*/}
            {/*                                                card.cardsDescription*/}
            {/*                                            }*/}
            {/*                                        </p>*/}
            {/*                                    </div>*/}
            {/*                                )*/}
            {/*                            }*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*            )*/}
            {/*        }*/}
            {/*    </div>*/}
            {/*    <div className={"hidden lg:flex w-1/4"}>*/}
            {/*        <div className={"h-fit p-2 sticky top-[100px] rounded-lg bg-main-100 flex flex-col"}>*/}
            {/*            {*/}
            {/*                sectionDetails.map((el) =>*/}
            {/*                    <a href={el.id}*/}
            {/*                       className={"font-bold p-2 hover:bg-main-500 hover:text-white rounded-md flex gap-2 items-center justify-start"}>*/}
            {/*                        <IoIosHammer/> {el.title}*/}
            {/*                    </a>*/}
            {/*                )*/}
            {/*            }*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
        </div>
    );
}