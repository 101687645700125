export default function TermsAndConditions() {

    return (
        <div className={"bg-main-background min-h-screen"}>
            <section
                className={"pb-20 text-center px-8 md:px-32 pt-[60px] md:pt-[90px] bg-white border-b border-main-500"}>
                <h1 className={"text-4xl py-5 font-main-bold"}>
                    Termeni si conditii
                </h1>
            </section>
            <section className={"p-10 text-justify flex flex-col gap-3"}>
                <h1 className={"font-main-bold text-xl"}>
                    1. Părţile contractante
                </h1>
                <p>
                    1.1. INOVIA S.R.L., cu sediul social în București, Calea Plevnei 145B, înregistrată la Registrul
                    Comerţului sub nr. J40/8172/2024, având cod unic de inregistrare RO 49962130, atribut fiscal RO,
                    având contul nr. RO76OTPV110001648799RO01 deschis la SUC. BUCURESTI OTP BANK ROMANIA, reprezentată
                    de dl Luca Andrei Orita – Administrator, operator al platformei www.inovia-trans.ro, si ale tuturor
                    subdomeniilor acesteia (incluzand, dar fara a se limita la app.inovia-trans.ro) în calitate de
                    Prestator.
                </p>
                <p></p>
                <p>si</p>
                <p></p>
                <p>
                    1.2. Clientul, cu datele de identificare din platforma www.inovia-trans.ro au agreat următoarele:
                    Acesta reprezintă contractul propus de INOVIA S.R.L. și trebuie acceptat înainte de comandarea
                    oricărui serviciu de pe site-ul www.inovia-trans.ro. Orice comandă confirmată de client, prin
                    bifarea căsuței – Am luat la cunoștință de Termenii si conditiile de utilizare a Inovia-Trans
                    reprezintă o acceptare din partea clientului a ofertei INOVIA S.R.L. în condițiile stipulate de art.
                    9 din Legea comerțului electronic 365/2002. Bifarea căsuței reprezintă o semnătură electronică, în
                    sensul art. 4 pct. 3 din Legea semnăturii electronice nr. 455/2001, având aceeași valoare cu o
                    semnatură olografă.
                </p>
                <p></p>
                <p className={"font-main-bold"}>
                    Definiții:
                </p>
                <p></p>
                <p>
                    <b>www.inovia-trans.ro</b>: site de internet, precum și ansamblul serviciilor accesibile pe acest
                    site web sau pe subdomeniile acestuia.
                    <br/>
                    Serviciu: serviciile puse la dispoziție de INOVIA S.R.L. prin intermediul site‐ului
                    www.inovia-trans.ro, în special cele de acces la aplicații de editarea comenzilor de transport,
                    editarea confirmarilor catre clienti, gestionare și administrare de documente financiar contabile,
                    sincronizarea datelor din contul de client, asistență pentru aceste servicii, backup de date de pe
                    orice tip de dispozitiv – de oriunde prin accesul la Internet – prin oferirea serviciilor sub forma
                    unui abonament lunar. Client: persoana juridică care face o comandă pe site‐ul www.inovia.ro sau se
                    înregistrează ca utilizator Comandă: comanda de achiziționare a unuia sau mai multor servicii
                    afișate în site‐ul www.inovia.ro, realizată de un client care își dorește să beneficieze de
                    acel/acele servicii. Vizitator: persoana fizică ce vizitează website‐ul www.inovia.ro fără a fi
                    achiziționat servicii și fără a fi făcut o Comandă.
                </p>
                <h1 className={"font-main-bold text-xl"}>
                    2. Obiectul și acceptarea prezentului Contract de prestări servicii
                </h1>
                <p>
                    2.1. Prezentul Contract de prestări servicii este aplicabil tuturor comenzilor sau conturilor
                    realizate prin intermediul www.inovia-trans.ro. Clientul se obligă să ia la cunoștință de aceste
                    condiții înainte de a realiza o comandă sau a-și deschide un cont. Toate comenzile și deschiderea
                    contului implică acceptarea de către Client a tuturor acestor condiții de prestări servicii.
                    Acceptarea prezentului contract de către client se face prin bifarea căsuței „Am luat la cunoștință
                    de Termenii si conditiile de utilizare a Inovia-Trans”.
                </p>
                <p>
                    2.2. INOVIA S.R.L. își rezervă dreptul de a modifica prezentele condiții de prestări servicii în
                    orice moment. Versiunea aplicabilă unei comenzi realizate este aceea în vigoare pe
                    www.inovia-trans.ro la momentul la care s‐a plasat comanda de către client.
                </p>
                <p>
                    2.3. În calitate de operator al site‐ului www.inovia-trans.ro, INOVIA S.R.L. prestează pentru Client
                    servicii de acces la aplicații de editare comenzi transport, editare confirmari clienti, gestionare
                    și administrare de documente financiar contabile, sincronizarea datelor din contul de client,
                    asistență pentru aceste servicii, backup de date de pe orice tip de dispozitiv – de oriunde, prin
                    accesul la Internet – prin oferirea serviciilor sub forma unui abonament lunar.
                </p>
                <p>
                    2.4. Serviciile pot fi accesate de pe o serie de interfețe precum: Chrome, Internet Explorer, Mozila
                    Firefox, Safari.
                </p>
                <p>
                    2.5. Pentru lista de servicii oferite de programul Inovia-Trans vezi aici:www.inovia-trans.ro.
                    Pretul lunar sau anual pentru inovia-trans se va stabili de catre INOVIA S.R.L. in functie de
                    necesitatile clientului si de perioada pe care se achita serviciul. Pretul se poate afla de la un
                    reprezentant INOVIA S.R.L. prin email sau telefonic. Plata serviciilor de catre Client reprezinta
                    acceptarea de catre acesta a pretului oferit de INOVIA S.R.L. pentru inovia-trans.
                </p>
                <p>
                    2.6. Prezentul contract de prestări servicii este însoțit și trebuie interpretat împreună cu
                    Termenii și Condițiile portalului www.inovia.ro, Acord de licență software pentru programul
                    inovia-trans, precum și cu Politica de confidențialitate:
                </p>
                <h1 className={"font-main-bold text-xl"}>
                    3. Durata contractului
                </h1>
                <p>
                    3.1. Prezentul contract se încheie pe o perioada nedeterminată începând de la data plasării comenzii
                    sau data creării contului, iar obligațiile părților intră în vigoare de la această dată.
                </p>
                <p>
                    3.2. Imediat ce se efectuează plata tipului de abonament lunar ales, Clientul va avea acces la
                    serviciile incluse în pachetul abonamentului din momentul confirmării plății de către Prestator.
                    Anularea comenzii se poate efectua până în momentul efectuării plătii. Orice erori survenite la
                    comanda facută de Client pot fi corectate până în momentul confirmarii plătii de către Prestator
                    printr‐un email trimis la contact@inovia-trans.com sau nr. de telefon 0359 800 801 de luni pana
                    vineri intre orele 09:00 si 17:00.
                </p>
                <p>
                    3.3. În cazul în care Clientul nu notifică renunțarea la abonamentul ales cu minim 15 zile
                    calendaristice înainte de expirare termenului de valabilitate a abonamentului, în fiecare lună
                    abonamentul se va prelungi automat fără nicio formalitate suplimentară.
                </p>
                <p>
                    3.4. În cazul în care Clientul dorește modificarea tipului de abonament ales pe parcursul perioadei
                    în care este activ un abonament anterior ales, acest lucru este posibil – schimbarea intrând în
                    vigoare în termen de 2 zile lucrătoare de la efectuarea plății diferenței, în cazul upgrade‐ului
                    unui pachet, iar în cazul downgrade‐ului unui pachet, intra în vigoare după ce expira perioada
                    pentru care clientul a plătit deja și efectuează o noua plată, pentru un pachet mai mic.
                </p>
                <p>
                    3.5. INOVIA S.R.L. oferă oricărui Client posibilitatea de a testa gratuit programul inovia-trans pe
                    o perioadă de 30 de zile. După expirarea acestei perioade, Clientul poate să opteze pentru un
                    abonament lunar. În cazul în care nu mai dorește continuarea serviciilor, accesul va fi întrerupt
                    definitiv, iar datele sale vor fi șterse în mod automat în termen de 60 de zile. Clientul are
                    posibilitatea de a cererea ștergerea imediată a datelor încărcate, iar acest lucru se va realiza în
                    maxim 2 zile lucrătoare.
                </p>
                <h1 className={"font-main-bold text-xl"}>
                    4. Valoarea contractului și efectuarea plății
                </h1>
                <p>
                    4.1. Pentru serviciile prestate, Clientul va plăti INOVIA S.R.L. o sumă lunară prevăzută în momentul
                    finalizării comenzii online, în funcție de tipul abonamentului ales.
                </p>
                <p>
                    4.2. INOVIA S.R.L. va trimite notificări de plată regulate în conformitate cu tipul de abonament
                    ales, iar pe baza acestora Clientul va efectua plata online sau prin transfer bancar.
                </p>
                <h1 className={"font-main-bold text-xl"}>
                    5. Disponibilitatea serviciilor afișate
                </h1>
                <p>
                    5.1 Oferta INOVIA S.R.L.. cu privire la servicii și prețul acestora este valabilă atâta vreme cât
                    este afișată pe www.inovia.ro. INOVIA S.R.L. își va îndeplini obligațiile contractuale imediat ce
                    clientul va efectua plata aferentă serviciului selectat.
                </p>
                <p>
                    5.2. Orice modificări ale tarifelor sau cu privire la disponibilitatea serviciilor vor fi comunicate
                    prin email la adresa de contact a clientului din contul de utilizator sau prin intermediul
                    aplicației cu cel puțin 30 de zile înaintea oricărei modificări. Serviciile de mentenanța ale
                    aplicației sunt anunțate prin intermediul aplicației sau prin email.
                </p>
                <p>
                    5.3. Clientul este responsabil cu actualizarea datelor din contul de utilizator imediat ce intervin
                    schimbări. INOVIA S.R.L. nu își asumă responsabilitatea în cazul în care intervin prejudicii sau
                    pagube de orice natură din cauza imposibilității de a accesa adresa de e‐mail declarată în contul de
                    utilizator.
                </p>
                <p>
                    5.4. Accesul la programul inovia-trans este disponibil până la renunțarea la serviciu și/sau
                    ștergerea contului de Client, indiferent de progresul tehnologic sau modificări, cu excepția cazului
                    în care Clientul nu își mai plătește abonamentul. Acest acces cuprinde și actualizări legislative
                    pentru serviciile disponibile, cât și asistența prin email pe întreaga perioadă de desfășurare a
                    prezentului contract.
                </p>
                <p>
                    5.5. INOVIA S.R.L. depune toate eforturile pentru a oferi permanent accesul la serviciile
                    www.inovia.ro, dar nu poate garanta disponibilitatea serviciului decât în măsură de 99.0%. Perioada
                    de disponibilitatea nu include perioadele de mentenanță anunțate în prealabil prin e-mail sau in
                    cadrul serviciului, însă include acele perioade de mentenanță neanunțate. Astfel, având în vedere
                    dependența serviciilor oferite de accesul la Internet, posibilele limitări tehnice și de sistem ce
                    pot apărea, INOVIA S.R.L. își asumă o obligație de diligență în ceea ce privește disponibilitatea
                    serviciilor conform procentului de mai sus. Dacă Clientul nu a putut accesa serviciul din motive
                    imputabile INOVIA S.R.L. pentru o perioadă mai mare decât ceva menționată mai sus, acesta poate cere
                    rambursarea proporțională a abonamentului plătit pentru perioada în care serviciul nu a fost
                    disponibil.
                </p>
                <p>
                    5.6. În condițiile în care serviciul dorit nu mai este disponibil, din motive imputabile INOVIA
                    S.R.L., Clientul își poate exporta documentele în format PDF, iar nomenclatoarele de produse și
                    clienți în format tabelar. În condițiile în care serviciul dorit nu mai este disponibil, din motive
                    imputabile Clientului (neplata, încalcara acestui contract etc), INOVIA S.R.L. se va stradui, fara a
                    garanta însă, sa ofere Clientului acces pentru a iși exporta documentele în format PDF, iar
                    nomenclatoarele de produse și clienți în format tabelar. INOVIA S.R.L.nu își poate asuma vreo
                    obligație legată de modul cum aceste date pot fi încărcate în alte sisteme informatice.
                </p>
                <p>
                    5.7. De asemenea, INOVIA S.R.L. depune toate eforturile rezonabile pentru asigurarea
                    confidențialității datelor primite de la Client și introduse în contul de utilizator sau folosite în
                    aplicațiile INOVIA S.R.L..
                </p>
                <h1 className={"font-main-bold text-xl"}>6. Drepturile și obligațiile părților</h1>
                <p>
                    6.1. Drepturile și obligațiile Clientului
                </p>
                <p>
                    6.1.1. Clientul este unicul responsabil pentru întreg conținutul existent în contul său sau pentru
                    orice încălcare a legii sau a drepturilor unei terțe persoane.

                </p>
                <p>
                    6.1.2. Clientul se obligă să folosească serviciile INOVIA S.R.L. în deplină legalitate și cunoscând
                    dispozițiile legale în vigoare cu privire la activitatea pe care o desfășoară.

                </p>
                <p>
                    6.1.3. Se obligă să respecte în totalitate prevederile legate de drepturile de autor și protecția
                    datelor cu caracter personal în ceea ce privește datele introduse în sistem.

                </p>
                <p>
                    6.1.4. Își asumă întreaga răspundere pentru crearea, personalizarea, administrarea, gestionarea,
                    acuratețea și legalitatea documentelor emise cu ajutorul serviciilor INOVIA S.R.L.

                </p>
                <p>
                    6.1.5. Clientul va procesa datele cu caracter personal conform prevederilor legale în vigoare și are
                    obligația de a respecta legislația în acest domeniu, iar INOVIA S.R.L. nu poate fi făcut responsabil
                    pentru niciun fel prejudicii sau încălcări ale drepturilor terțelor persoane.

                </p>
                <p>
                    6.1.6. Are dreptul de a adăuga în sistemul informatic mai mulți utilizatori ai contului cu diverse
                    drepturi de acces – în totalitate sau în parte – în contul său. De asemenea poate delega atribuțiile
                    sale unuia sau mai multor utilizatori.

                </p>
                <p>
                    6.1.7. Înțelege și este de acord cu Termenii și Condițiile portalului www.inovia.ro, cu Acord de
                    licență software pentru programul inovia-trans, precum și cu Politica de confidențialitate:

                </p>
                <p>
                    6.2. Drepturile și obligațiile INOVIA S.R.L.

                </p>
                <p>
                    6.2.1. INOVIA S.R.L. asigură:

                </p>
                <p className={"pl-10"}>
                    <ol className={"list-disc"}>
                        <li>accesul la platforma tehnică necesară pentru crearea, personalizarea, administrarea și
                            gestionarea documentelor emise cu ajutorul programului inovia-trans. Există și posibilitatea
                            utilizării gratuite a serviciilor. Clientul care utilizează în mod gratuit aplicația,
                            beneficiază de funcționalitățile ei în mod limitat, așa cum sunt ele prevăzute în acel
                            moment pe situl www.inovia.ro, și are posibilitatea de a cererea ștergerea imediată a
                            datelor încărcate, iar acest lucru se va realiza în maxim 2 zile lucrătoare de la
                            confirmarea cererii.
                        </li>
                        <li>
                            asistență de luni până vineri între orele 09:00 – 17:00 pentru probleme legate de aplicații.
                            Nu oferim asistență în legătură cu emiterea de documente.
                        </li>
                        <li>
                            backup zilnic al datelor
                        </li>
                        <li>
                            conectarea la sistem printr-un protocol sigur de comunicare (https)
                        </li>
                        <li>
                            sincronizarea datelor din contul de utilizator în așa fel încât să fie disponibile de pe
                            orice tip de dispozitiv, de oriunde, prin accesul la Internet. Serviciile Weblike PRO S.R.L
                            nu funcționează sau funcționează în mod limitat în varianta offline.
                        </li>
                    </ol>
                </p>
                <p>
                    6.2.2. www.inovia.ro acționează ca un găzduitor pentru conținutul adăugat de Client, în condițiile
                    Legii 365/2002 privind comerțul electronic. În același timp, o parte limitată de date sunt stocate
                    și pe calculatoarele Clientului. În schimb, datele nu sunt păstrate în browser sau pe mobil. INOVIA
                    S.R.L. nu își asumă responsabilitatea pentru pierderea parolelor de acces la contul de utilizator
                    sau pentru activități ce pot compromite contul Clientului. În cazul în care INOVIA S.R.L. primește
                    notificari, înștiințări sau adrese cu privire la existența unor servicii sau activități cu o
                    aparență înșelătoare (de tip „spam”, „phishing”, etc.) sau nelegală realizată de către clienți, își
                    rezervă dreptul de a suspenda contul de utilizator sau de a bloca accesul la acesta.
                </p>
                <p>
                    6.2.3. Fiecare Client va avea un sistem de utilizatori cărora li se vor putea configura un anumit
                    set de drepturi conform funcționalităților din site. Clientul poartă întreaga responsabilitate în
                    cazul în care utilizatorii aprobați de către Client încalcă dispozițiile documentelor precizate la
                    punctul 2.6 al prezentului contract sau se angajează în activități ilegale sau imorale.
                </p>
                <p>
                    6.2.4. Backup‐ul datelor se realizează zilnic, Clienții având acces la versiunile anterioare ale
                    datelor prin serviciul de Cloud.
                </p>
                <p>
                    6.2.5 Dacă din baza de date de pe calculator nu se poate face o restaurare, INOVIA S.R.L. va asista
                    clientul în vederea accesării bazei de date.
                </p>
                <p>
                    6.2.6. INOVIA S.R.L. nu monitorizează și nu exercită nici un control asupra datelor și documentelor
                    Clientului.
                </p>
                <p>
                    6.2.7. INOVIA S.R.L. oferă un drept de utilizare (licență) neexclusivă și nelimitată în timp și
                    spațiu pentru folosirea aplicațiilor de pe site-ul www.inovia.ro.
                </p>
                <p>
                    6.2.8. În cazul în care uptime‐ul este sub 99.0% vom oferi despăgubiri procentuale din valoarea
                    abonamentului conform articolului 5.5.
                </p>
                <p>
                    6.2.9 INOVIA S.R.L. are dreptul de a colecta date anonime despre modul de utilizare a serviciilor
                    sale si de a le face publice în mod agregat.
                </p>
                <h1 className={"font-main-bold text-xl"}>
                    7. Înregistrare, parole și responsabilități
                </h1>
                <p>
                    7.1. Înregistrarea pe site-ul www.inovia.ro.
                    <br/>
                    Accesul Clientului la anumite funcționalități din cadrul site-ului (exemplu: programul inovia-trans)
                    este permis în funcție de tipul abonamentului lunar pentru care au optat. Vă recomandăm să nu
                    dezvăluiți nimănui parolele de acces.
                </p>
                <p>
                    7.2. Din păcate, nicio transmisie de date prin intemediul internetului nu poate fi garantată ca find
                    100% sigură. În consecință, în ciuda eforturilor noastre de a vă proteja informația și de a folosi
                    protocoale securizate de transmitere a informației (https), INOVIA S.R.L. nu poate asigura sau
                    garanta securitatea informațiilor transmise de dumneavoastră către noi. Vă avertizăm așadar că orice
                    informație trimisă către noi se va face pe propriul dumneavoastră risc.
                </p>
                <p>
                    7.3. Orice acces neautorizat la elementele ne‐publice ale site‐ului www.inovia.ro sau accesul altor
                    persoane în afara celor autorizate la un cont găzduit de noi, reprezintă infracțiunea de acces fără
                    drept la un sistem informatic și va fi sancționată conform legislației române în vigoare.
                </p>
                <h1 className={"font-main-bold text-xl"}>
                    8. Răspunderea contractuală
                </h1>
                <p>
                    8.1. Clientul garantează datele introduse și poartă întreaga responsabilitate pentru felul și scopul
                    în care utilizează serviciile puse la dispoziție de INOVIA S.R.L. precum și pentru felul în care
                    configurează sistemul de utilizatori ce au acces la contul Clientului și comportamentul acestora.
                </p>
                <p>
                    8.2. Clientul garantează informația pe care o furnizează către www.inovia.ro și este singurul
                    responsabil cu privire la prezentarea informațiilor într‐un mod corect și complet, precum și cu
                    păstrarea acurateții informațiilor sau a actualizării lor.
                </p>
                <p>
                    8.3. INOVIA S.R.L. nu poate fi ținut răspunzător în cazul în care Clientul se folosește de
                    aplicațiile și serviciile puse la dispoziție în scopuri ilegale sau imorale.
                </p>
                <p>
                    8.4. Clientul este de acord că este singurul responsabil și va despăgubi INOVIA S.R.L. pentru orice
                    pagubă, costuri sau limitări de profit care apar ca urmare a oricăror acțiuni frauduloase din partea
                    acestuia. Prin prezentul document Clientul înțelege și acceptă faptul că INOVIA S.R.L. va transmite
                    datele acestuia către organele de anchetă, în cazul în care primește o cerere motivată de la o
                    instituție publică autorizată.
                </p>
                <p>
                    8.5. Deși în fiecare moment face eforturi în vederea asigurării calității și corectitudinii
                    mesajelor publicate în site, INOVIA S.R.L. nu poate garanta, expres sau implicit, în privința
                    conținutului, a software‐ului ori a produselor și serviciilor publicate sub egida sa. INOVIA S.R.L.
                    nu își asumă răspunderea, în nicio situație, pentru nicio daună sau eroare pricinuită direct sau
                    indirect, pentru nicio lipsă de profit directă sau indirectă (incluzând, dar fără a se limita la
                    această enumerare: daune pentru pierderea profitului, întreruperea unor afaceri, sau alte daune
                    pecuniare), suferite ca urmare a utilizării sau a întreruperii utilizării ori a lipsei regularității
                    informațiilor și serviciilor furnizate de site.
                </p>
                <p>
                    8.6. INOVIA S.R.L. nu garantează acuratețea, corectitudinea sau caracterul actual al informațiilor
                    ori serviciilor furnizate de site.
                </p>
                <h1 className={"font-main-bold text-xl"}>
                    9. Încetarea contractului
                </h1>
                <p className={""}>
                    9.1. Prezentul contract încetează în următoarele cazuri:
                    <ol className={"pl-10 list-disc"}>
                        <li>
                            părțile convin de comun acord încetarea contractului;
                        </li>
                        <li>
                            neîndeplinirea sau îndeplinirea defectuoasă repetată a obligațiilor contractuale de către
                            una din părți;
                        </li>
                        <li>
                            decizia unilaterală a uneia dintre părți, transmisă în scris celeilalte părți; recepția
                            notificării de încetare trebuie să aibă loc cu minimum 30 zile calendaristice înainte de
                            data stabilită pentru încetarea colaborării;
                        </li>
                        <li>
                            în caz de dizolvare, lichidare, faliment, retragerea autorizației de funcționare a unuia din
                            contractanți, caz în care părțile vor fi ținute a îndestula datoriile uneia către alta,
                            datorii rezultate până la momentul intervenției cauzei de dispariție.
                        </li>
                    </ol>
                </p>
                <h1 className={"font-main-bold text-xl"}>
                    10. Exonerarea de răspundere
                </h1>
                <p>
                    10.1. INOVIA S.R.L. nu va răspunde pentru niciun prejudiciu material sau moral, orice pagubă sau
                    costuri ce pot interveni din întârzierea plăților ce sunt în sarcina Clientului, precum și
                    încălcarea de către acesta a oricăror obligații legale dacă nu sunt din vina INOVIA S.R.L..
                </p>
                <h1 className={"font-main-bold text-xl"}>11. Confidențialitate</h1>
                <p className={""}>
                    11.1. Nici una din Părţile contractante nu are dreptul, fără acordul scris prealabil al celeilalte
                    Părţi:
                    <ol className={"pl-10 list-disc"}>
                        <li>
                            de a face cunoscută orice informație confidențială unei terţe părţi, în afara acelor
                            persoane implicate în derularea şi executarea contractului;
                        </li>
                        <li>
                            de a utiliza orice informaţie confidențială sau la care are acces în perioada de derulare a
                            contractului, în alt scop decât acela de a‐şi executa obligaţiile asumate. Toate
                            informațiile confidențiale trebuie marcate ca atare de partea care le invocă a fi
                            confidențiale.
                        </li>
                    </ol>
                </p>
                <p className={""}>
                    11.2. Restricţia prevăzută anterior nu va fi aplicabilă dacă:
                    <ol className={"pl-10 list-disc"}>
                        <li>
                            informaţia era cunoscută părţii contractante înainte ca ea să fi fost primită în executarea
                            prezentului contract;
                        </li>
                        <li>
                            informaţia era accesibilă publicului;
                        </li>
                        <li>
                            partea în cauză a fost obligată în conformitate cu dispoziţiile legale să dezvăluie
                            informaţiile în cauză.
                        </li>
                    </ol>
                </p>
                <h1 className={"font-main-bold text-xl"}>
                    12. Forța majoră
                </h1>
                <p>
                    12.1. Forța majoră exonerează de răspundere părțile, în cazul neexecutării parțiale sau totale a
                    obligațiilor asumate prin prezentul contract. Prin forța majoră se înțelege un eveniment independent
                    de voința părților, imprevizibil și insurmontabil, apărut după încheierea contractului și care
                    împiedică părțile să execute total sau parțial obligațiile asumate.
                </p>
                <p>
                    12.2. Partea care invocă forța majoră are obligația să o aducă la cunoștința celeilalte părti, în
                    scris, în maximum 5 zile de la apariție.
                </p>
                <p>
                    12.3. Partea care invocă forța majoră are obligația să aducă la cunostință celeilalte părți
                    încetarea cauzei acesteia în maximum 15 zile de la încetare.
                </p>
                <p>
                    12.4. Dacă aceste împrejurări și consecințele lor durează mai mult de 2 luni, fiecare partener poate
                    renunța la executarea contractului pe mai departe. În acest caz, nici una din părți nu are dreptul
                    de a cere despăgubiri de la cealaltă parte, dar ele au îndatorirea de a-și onora toate obligațiile
                    până la această dată.
                </p>
                <h1 className={"font-main-bold text-xl"}>
                    13. Legea guvernantă
                </h1>
                <p>
                    13.1. Drepturile și obligațiile părților impuse de prezentul contract, precum și toate efectele
                    juridice pe care acesta le produce vor fi interpretate și guvernate de legea română în vigoare.
                </p>
                <p>
                    13.2. Orice litigiu care are ca obiect acest acord va fi soluționat pe cale amiabilă, iar în cazul
                    în care soluționarea amiabilă nu este posibilă va fi adus spre soluționare în fața unui arbitru. În
                    condițiile în care părțile nu cad de acord asupra numirii unui arbitru în termen de 15 zile de la
                    notificarea litigiului, competența va reveni instanțelor românești de la sediul INOVIA S.R.L..
                </p>
                <h1 className={"font-main-bold text-xl"}>
                    14. Dispoziții finale
                </h1>
                <p>
                    14.1. Părțile declară că au negociat toate clauzele prezentului contract și acestea sunt acceptate
                    în mod expres prin semnarea contractului, orice înțelegere anterioară neproducând efecte juridice
                    între acestea.
                </p>
                <p>
                    14.2. Prezentul contract poate fi modificat numai prin acordul părților prin încheierea unui act
                    adițional la prezentul contract.
                </p>
                <p>
                    14.3. Orice notificare către INOVIA S.R.L. trebuie trimisă electronic la adresa de e-mail
                    contact@inovia-trans.com.
                </p>
            </section>
        </div>
    )
        ;
}