import MainAppRouter from "../../../router/MainAppRouter";
import {GrSend} from "react-icons/gr";
import React, {useState} from "react";
import {toast} from "react-hot-toast";
import ContactService from "../../../services/ContactService";

export default function ContactForm() {
    const [isTermsChecked, setIsTermsChecked] = useState(false);
    const [isPolicyChecked, setIsPolicyChecked] = useState(false);
    const [emailRequest, setEmailRequest] = useState({
        name: null,
        email: null,
        phone: null,
        company: null,
        message: null,
    });
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (e) => {
        const {name, value} = e.target;
        setEmailRequest((prev) => ({
            ...prev,
            [name]: value
        }));
    }

    const emptyRequest = () => {
        setEmailRequest({
            name: null,
            email: null,
            phone: null,
            company: null,
            message: null,
        });
    }

    const sendEmail = (e) => {
        e.preventDefault();
        if (emailRequest.email === null) {
            toast.error("Completeaza-ti emailul");
            return;
        }

        if (emailRequest.phone === null) {
            toast.error("Completeaza-ti telefonul");
            return;
        }

        if (emailRequest.name === null) {
            toast.error("Completeaza-ti numele complet");
            return;
        }

        if (emailRequest.company === null) {
            toast.error("Completeaza-ti numele companiei");
            return;
        }
        if (emailRequest.message === null) {
            toast.error("Completeaza oferta care te intereseaza");
            return;
        }

        setIsLoading(true)
        new ContactService().contact(emailRequest)
            .then((response) => {
                emptyRequest();
                toast.success(response.message)
            })
            .catch((_) => toast.error("Am intampinat o eroare."))
            .finally(() => setIsLoading(false));
    }

    return (
        <form className="space-y-4" onSubmit={sendEmail}>
            <div>
                <label className="text-sm font-main-medium mb-1" htmlFor="email">
                    Numele tau
                </label>
                <input type="text"
                       id="email"
                       name={"name"}
                       onChange={handleInputChange}
                       className="outline-main-500 w-full p-2 border border-input rounded-md"
                       placeholder="Nume Prenume"/>
            </div>
            <div>
                <label className="text-sm font-main-medium mb-1" htmlFor="email">
                    Adresa ta de email
                </label>
                <input type="email"
                       id="email"
                       name={"email"}
                       onChange={handleInputChange}
                       className="outline-main-500 w-full p-2 border border-input rounded-md"
                       placeholder="adresa@provider.com"/>
            </div>
            <div>
                <label className="text-sm font-main-medium mb-1" htmlFor="phone">
                    Numarul de telefon
                </label>
                <input type="phone"
                       id="phone"
                       name={"phone"}
                       onChange={handleInputChange}
                       className="outline-main-500 w-full p-2 border border-input rounded-md"
                       placeholder="07xxxxxxxx"/>
            </div>
            <div>
                <label className="text-sm font-main-medium mb-1" htmlFor="company">
                    Compania ta
                </label>
                <input type="text" id="company"
                       name={"company"}
                       onChange={handleInputChange}
                       className="outline-main-500 w-full p-2 border border-input rounded-md"
                       placeholder="SC xxxx SRL"/>
            </div>
            <div>
                <label className="text-sm font-main-medium mb-1" htmlFor="message">
                    Lasă-ne un mesaj
                </label>
                <textarea id="message"
                          name={"message"}
                          onChange={handleInputChange}
                          className="outline-main-500 w-full p-2 border border-input rounded-md"
                          rows="4"
                          placeholder="Adaugă detalii relevante în scopul unei oferte personalizate"></textarea>
            </div>
            <div className={"w-full flex justify-between gap-5 items-center"}>
                <div className="pt-3 text-sm w-2/3">
                    <p className="font-main-light">
                        <input onChange={(e) => setIsPolicyChecked(e.target.checked)}
                               type="checkbox"
                               className="w-4 h-4 accent-main-500 mr-2"/>
                        Prin transmiterea formularului sunt de acord cu
                        <a href={MainAppRouter.privacyPolicy} className="ml-1 text-main-500">
                            Politica GDPR
                        </a>
                    </p>
                    <p className="font-main-light">
                        <input onChange={(e) => setIsTermsChecked(e.target.checked)} type="checkbox"
                               className="w-4 h-4 accent-main-500 mr-2"/>
                        Prin transmiterea formularului sunt de acord cu
                        <a href={MainAppRouter.termsAndConditions} className="ml-1 text-main-500">
                            Termenii și Condiții de utilizare
                        </a>
                    </p>
                </div>
                {
                    isLoading
                        ? <></>
                        : <button
                            disabled={!(isPolicyChecked && isTermsChecked)}
                            type="submit"
                            className="w-1/3 flex justify-center items-center gap-2 disabled:bg-gray-300 bg-main-500 text-white font-main-medium p-2 rounded-full">
                            Trimite <GrSend/>
                        </button>
                }
            </div>
        </form>
    );
}