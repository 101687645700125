import React, {useEffect, useState} from 'react';
import MainAppRouter from '../../router/MainAppRouter';
import headerLinks from './HeaderLinks';
import {IoBookmark, IoClose} from "react-icons/io5";
import {Toaster} from "react-hot-toast";
import ContactForm from "../../views/contact/contact-form/ContactForm";

const Header = () => {
    const [isScrolled, setIsScrolled] = useState(false);
    const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
    const [popupDemoBook, setPopupDemoBook] = useState(false);


    useEffect(() => {
        const handleScroll = () => {
            setIsScrolled(window.scrollY > 200);
        };

        window.addEventListener('scroll', handleScroll);

        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    const bookDemo = () => {
        setPopupDemoBook(true);
    }

    const closeDemo = () => {
        setPopupDemoBook(false);
    }

    return (
        <header className="font-main-medium flex justify-center">
            <div><Toaster/></div>
            <div
                className={`z-30 fixed w-full lg:w-3/4 px-4 py-2 h-[66px] flex justify-between items-center ${isScrolled ? 'bg-white shadow-lg lg:bg-transparent lg:shadow-none' : ''}`}>
                <div className="h-full flex gap-2 items-center">
                    <img
                        src="/logo.png"
                        className="h-10 lg:h-full border-[1px] bg-white shadow-lg border-main-500 rounded-2xl p-2"
                        alt=""
                    />
                    <nav
                        className="hidden lg:flex border-[1px] bg-white p-1 h-fit shadow-lg gap-1 border-main-500 rounded-2xl justify-center items-center">
                        {headerLinks.map((el, index) => (
                            <a
                                href={el.link}
                                key={index}
                                className="hover:bg-main-500 text-gray-900 hover:text-white flex items-center cursor-pointer rounded-xl py-2 px-3">
                                {el.text}
                            </a>
                        ))}
                    </nav>
                </div>
                <div className="h-full flex gap-2 items-center">
                    <div
                        className="hidden lg:flex border-[1px] bg-white p-1 h-fit shadow-lg gap-1 border-main-500 rounded-2xl justify-center items-center">
                        <button
                            onClick={bookDemo}
                            className="hover:bg-main-500 text-gray-900 hover:text-white flex items-center cursor-pointer rounded-xl py-2 px-3">
                            Solicita oferta
                        </button>
                    </div>
                    <button
                        className="lg:hidden text-main-500"
                        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}>
                        <svg
                            className="w-6 h-6"
                            fill="none"
                            stroke="currentColor"
                            viewBox="0 0 24 24"
                            xmlns="http://www.w3.org/2000/svg">
                            <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M4 6h16M4 12h16M4 18h16"
                            ></path>
                        </svg>
                    </button>
                    <a
                        href={MainAppRouter.portal}
                        className="rounded-2xl h-full flex items-center font-main-bold bg-main-500 py-2 px-4 text-white shadow-lg">
                        Portal
                    </a>
                </div>
            </div>

            {isMobileMenuOpen && (
                <nav
                    className="lg:hidden fixed top-[66px] left-0 w-full bg-white shadow-lg border-t border-main-500 z-30">
                    <div className="flex flex-col p-4">
                        {headerLinks.map((el, index) => (
                            <div
                                key={index}
                                className="hover:bg-main-500 text-gray-900 hover:text-white flex items-center cursor-pointer rounded-xl py-2 px-3 mb-2">
                                <a href={el.link}>{el.text}</a>
                            </div>
                        ))}
                        <button
                            onClick={bookDemo}
                            className="hover:bg-main-500 text-gray-900 hover:text-white flex items-center cursor-pointer rounded-xl py-2 px-3 mb-2">
                            Solicita oferta
                        </button>
                    </div>
                </nav>
            )}
            {
                popupDemoBook
                    ? <div
                        className={"w-screen h-screen fixed inset-0 p-10 z-40 bg-black bg-opacity-90 flex justify-center items-center"}>
                        <div className={"w-full lg:w-2/5 h-fit rounded-xl bg-white p-5"}>
                            <div className={"flex justify-end items-center"}>
                                <button
                                    onClick={closeDemo}
                                    className={"bg-gray-300 p-2 rounded-full hover:bg-main-500 hover:text-white text-gray-500"}>
                                    <IoClose/>
                                </button>
                            </div>
                            <h1 className={"text-3xl font-main-bold pb-3 text-center flex items-center justify-center gap-2"}>
                                <IoBookmark className={"text-main-500 mb-1"}/>
                                Solicită oferta
                            </h1>
                            <ContactForm/>
                        </div>
                    </div>
                    : <></>
            }
        </header>
    );
};

export default Header;
