import React, {useState} from 'react';
import {HiClock, HiOutlineLocationMarker, HiOutlineMail} from "react-icons/hi";
import {MdOutlineLocalPhone} from "react-icons/md";
import {toast} from "react-hot-toast";
import ContactService from "../../services/ContactService";
import MainAppRouter from "../../router/MainAppRouter";
import {GrSend} from "react-icons/gr";
import ContactForm from "./contact-form/ContactForm";

const ContactPage = () => {
    return (
        <div className={"bg-main-background min-h-screen py-12 xl:my-0"}>
            <div className="bg-background text-foreground px-8 md:p-32">
                <div className="flex xl:flex-row flex-col gap-4 pt-5">
                    <div className={"w-full xl:w-2/3 flex flex-col gap-4"}>
                        <div className={"w-full bg-white p-5 rounded-xl border-[1px] border-gray-300 shadow-lg"}>
                            <h1 className={"font-main-bold text-3xl"}>
                                Contactează-ne! 📬
                            </h1>
                            <p className={"font-main-medium mt-3"}>
                                Vrei să afli mai multe informații cu privire la soluția Inovia și despre ce avantaje îți
                                poate oferi? Contactează-ne acum și îți oferim în cel mai scurt timp toate detaliile de
                                care ai nevoie.
                            </p>
                        </div>
                        <div className={"flex flex-col md:flex-row gap-4"}>
                            <div
                                className={"flex flex-col w-full bg-white p-5 rounded-xl border-[1px] border-gray-300 shadow-lg"}>
                                <div className={"h-full"}>
                                    <h1 className={"font-main-bold text-3xl"}>
                                        Date de contact
                                    </h1>
                                    <div className={"w-full flex flex-col gap-2 pt-2"}>
                                        <div className={"flex gap-2 items-center justify-start"}>
                                            <div className={"rounded-full bg-main-500 p-2"}>
                                                <MdOutlineLocalPhone className={"text-white"}/>
                                            </div>
                                            <a href={"tel:+40785225446"}>(+40) 785 225 446</a>
                                        </div>
                                        <div className={"flex gap-2 items-center justify-start"}>
                                            <div className={"rounded-full bg-main-500 p-2"}>
                                                <HiOutlineMail className={"text-white"}/>
                                            </div>
                                            <a href={"mailTo:contact@inovia-trans.ro"}>contact@inovia-trans.ro</a>
                                        </div>
                                    </div>
                                </div>
                                <div className={"h-full mt-10 md:mt-0"}>
                                    <h1 className={"font-main-bold text-3xl"}>
                                        Orar
                                    </h1>
                                    <div className={"w-full flex flex-col gap-2 pt-2"}>
                                        <div className={"flex gap-2 items-start justify-start"}>
                                            <div className={"rounded-full bg-main-500 p-2"}>
                                                <HiClock className={"text-white"}/>
                                            </div>
                                            <span>Luni - Vineri: 08:00 - 22:00 <br/> Sâmbătă: 08:00 - 16:30 <br/> Duminică: Închis</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className={"w-full bg-white p-5 rounded-xl border-[1px] border-gray-300 shadow-lg"}>
                                <h1 className={"font-main-bold text-3xl"}>
                                    Adresa
                                </h1>
                                <div className={"w-full flex flex-col gap-2 pt-2"}>
                                    <div className={"flex gap-2 items-center justify-start"}>
                                        <div className={"rounded-full bg-main-500 p-2"}>
                                            <HiOutlineLocationMarker className={"text-white"}/>
                                        </div>
                                        <span>Calea Plevnei 145B, Bucuresti, Sector 6</span>
                                    </div>
                                </div>
                                <iframe
                                    className={"border-0 py-2 w-full h-[350px]"}
                                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2848.4619466660347!2d26.063369623731408!3d44.444198088311836!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40b201e5e85bd5d3%3A0xe3fb598a2107cd4!2sCalea%20Plevnei%20145b%2C%20Bucure%C8%99ti%20060012!5e0!3m2!1sen!2sro!4v1720103281488!5m2!1sen!2sro"
                                    allowFullScreen="" loading="lazy"/>
                            </div>
                        </div>
                    </div>
                    <div className={"w-full xl:w-1/3 bg-white p-5 rounded-xl border-[1px] border-gray-300 shadow-lg"}>
                        <h1 className={"font-main-bold text-2xl mb-2"}>Formular contact</h1>
                        <ContactForm/>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ContactPage;