import {FaCheck} from "react-icons/fa6";
import MainAppRouter from "../../router/MainAppRouter";
import {IoBookmark} from "react-icons/io5";
import React from "react";

export default function Pricing() {

    return (
        <div className={"bg-main-background min-h-screen"}>
            <section
                className={"pb-20 text-center px-8 md:px-32 pt-[60px] md:pt-[90px] bg-white flex justify-center items-center"}>
                <div className={"border border-main-500 p-4 rounded-xl max-w-[1120px]"}>
                    <h1 className={"text-4xl py-5 font-main-bold"}>Lista de preturi</h1>
                    <p className={"px-10 py-1.5"}>
                        Profitați de oferta promoțională de lansare a produsului în <b className={"text-main-500"}>versiunea
                        BETA</b>!
                    </p>
                    <p className={"px-10 py-1.5"}>
                        Prețurile afișate
                        reflectă un cost special pentru clienții care aleg să ne susțină în această etapă. În plus,
                        pentru a răspunde cât mai bine nevoilor afacerii dvs., oferim posibilitatea de a <b
                        className={"text-main-500"}> personaliza
                        oferta de preț</b>.
                    </p>
                    <p className={"px-10 py-1.5"}>
                        Contactați-ne pentru o soluție adaptată cerințelor și obiectivelor echipei
                        dvs.
                    </p>
                    <div className={"flex justify-center items-center py-1.5"}>
                        <a href={MainAppRouter.contact}
                           className={"w-fit flex gap-1 justify-center items-center px-8 font-main-bold py-4 bg-main-500 text-white rounded-xl"}>
                            <IoBookmark className={"text-white mb-1"}/>
                            Contacteaza-ne!
                        </a>
                    </div>
                </div>
            </section>
            <section className={"pb-20 px-8 md:px-32"}>
                <h1 className={"text-4xl text-center py-5 font-main-bold"}>Abonamentul pentru DocuChecker</h1>
                <div className={"flex md:flex-row flex-col gap-10 justify-center"}>
                <div
                        className={"rounded-lg shadow-lg border border-gray-400 w-full md:w-1/3 bg-white p-5 text-center"}>
                        <p className={"text-transparent"}>Recomandat</p>
                        <h1 className={"font-main-bold text-3xl"}>
                            Abonament Lunar
                        </h1>
                        <p className={"text-center text-3xl font-main-bold mt-2"}>
                            <span>10€ </span><span className={"text-sm"}>+ TVA</span>
                        </p>
                        <p className={"text-transparent font-main-medium"}>
                            de ce te uiti?
                        </p>
                        <div className={"mt-5 flex justify-center"}>
                            <div className={"w-fit h-fit"}>
                                <p className={"flex gap-3 items-center"}><FaCheck className={"text-main-500 mb-1"}/> 4
                                    utilizatori + admin</p>
                                <p className={"flex gap-3 items-center"}><FaCheck className={"text-main-500 mb-1"}/> 40
                                    credite</p>
                                <p className={"flex gap-3 items-center"}><FaCheck className={"text-main-500 mb-1"}/> 1GB
                                    memorie</p>
                            </div>
                        </div>
                        <div className={"flex justify-center"}>
                            <div onClick={() => window.open(MainAppRouter.portalShop)}
                                 className={"font-main-bold cursor-pointer flex rounded-xl bg-main-500 w-fit px-6 py-3 mt-6 text-white"}>
                                Cumpara
                            </div>
                        </div>
                        <hr className={"my-4"}/>
                        <h2 className={"text-gray-400 font-main-bold mb-2"}>Detalii</h2>
                        <div className={"flex justify-center"}>
                            <div className={"text-gray-400"}>
                                <p className={"flex gap-3 items-center"}>
                                    * Utilizator suplimentar 2 €/luna + TVA
                                </p>
                                <p className={"flex gap-3 items-center"}>
                                    * Fiecare credit reprezinta o pagina scanata
                                </p>
                            </div>
                        </div>
                    </div>
                    <div
                        className={"rounded-lg shadow-lg border border-main-500 w-full md:w-1/3 bg-white p-5 text-center"}>
                        <p className={"text-main-500"}>Recomandat</p>
                        <h1 className={"font-main-bold text-3xl"}>
                            Abonament Anual
                        </h1>
                        <p className={"text-center text-3xl font-main-bold mt-2"}>
                            <span>90€</span><span className={"text-sm"}> + TVA</span>
                        </p>
                        <p className={"text-main-500 font-main-medium"}>
                            Salvezi 25%
                        </p>
                        <div className={"mt-5 flex justify-center"}>
                            <div className={"w-fit h-fit"}>
                                <p className={"flex gap-3 items-center"}><FaCheck className={"text-main-500 mb-1"}/>
                                    4
                                    utilizatori + admin
                                </p>
                                <p className={"flex gap-3 items-center"}><FaCheck className={"text-main-500 mb-1"}/>
                                    480
                                    credite
                                </p>
                                <p className={"flex gap-3 items-center"}><FaCheck className={"text-main-500 mb-1"}/>
                                    1GB
                                    memorie
                                </p>
                            </div>
                        </div>
                        <div className={"flex justify-center"}>
                            <div onClick={() => window.open(MainAppRouter.portalShop)}
                                 className={"font-main-bold cursor-pointer flex rounded-xl bg-main-500 w-fit px-6 py-3 mt-6 text-white"}>
                                Cumpara
                            </div>
                        </div>
                        <hr className={"my-4"}/>
                        <h2 className={"text-gray-400 font-main-bold mb-2"}>Detalii</h2>
                        <div className={"flex justify-center"}>
                            <div className={"text-gray-400"}>
                                <p className={"flex gap-3 items-center"}>
                                    * Utilizator suplimentar 1,5 €/luna + TVA
                                </p>
                                <p className={"flex gap-3 items-center"}>
                                    * Fiecare credit reprezinta o pagina scanata
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className={"py-20 px-8 md:px-32 bg-white"}>
                <h1 className={"text-4xl text-center pb-5 font-main-bold"}>Credite pentru Abonamentul DocuChecker</h1>
                <div className={"flex md:flex-row flex-col gap-10 justify-center"}>
                    <div className={"rounded-lg shadow-lg border border-gray-400 w-full bg-white p-5 text-center"}>
                        <p className={"text-transparent mb-1"}>de ce te uiti aici?</p>
                        <h1 className={"font-main-bold text-3xl"}>
                            Basic
                        </h1>
                        <p className={"text-center text-3xl font-main-bold mt-2"}>
                            <span>15€ </span>
                            <span className={"text-sm"}>+ TVA</span>
                        </p>
                        <div className={"mt-5 flex justify-center"}>
                            <div className={"w-fit h-fit"}>
                                <p className={"flex gap-3 font-main-medium items-center"}>
                                    <FaCheck className={"text-main-500 mb-1"}/>
                                    50 credite
                                </p>
                                <p className={"flex gap-3 items-center text-sm text-gray-500 justify-center"}>
                                    0,3 €/credit
                                </p>
                            </div>
                        </div>
                        <div className={"flex justify-center"}>
                            <div onClick={() => window.open(MainAppRouter.portalShop)}
                                 className={"font-main-bold cursor-pointer flex rounded-xl bg-main-500 w-fit px-6 py-3 mt-6 text-white"}>
                                Cumpara
                            </div>
                        </div>
                        <hr className={"my-4"}/>
                        <h2 className={"text-gray-400 font-main-bold mb-2"}>Detalii</h2>
                        <div className={"flex justify-center"}>
                            <div className={"text-gray-400"}>
                                <p className={"flex gap-3 items-center"}>
                                    * Fiecare credit reprezinta o pagina scanata
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={"rounded-lg shadow-lg border border-gray-400 w-full bg-white p-5 text-center"}>
                        <p className={"text-transparent mb-1"}>Recomandat</p>
                        <h1 className={"font-main-bold text-3xl"}>
                            Silver
                        </h1>
                        <p className={"text-center text-3xl font-main-bold mt-2"}>
                            <span>50€ </span><span className={"text-sm"}>+ TVA</span>
                        </p>
                        <div className={"mt-5 flex justify-center"}>
                            <div className={"w-fit h-fit"}>
                                <p className={"flex gap-3 font-main-medium items-center"}><FaCheck
                                    className={"text-main-500 mb-1"}/>
                                    200
                                    credite
                                </p>
                                <p className={"flex gap-3 items-center text-sm text-gray-500 justify-center"}>
                                    0,25 €/credit
                                </p>
                            </div>
                        </div>
                        <div className={"flex justify-center"}>
                            <div onClick={() => window.open(MainAppRouter.portalShop)}
                                 className={"font-main-bold cursor-pointer flex rounded-xl bg-main-500 w-fit px-6 py-3 mt-6 text-white"}>
                                Cumpara
                            </div>
                        </div>
                        <hr className={"my-4"}/>
                        <h2 className={"text-gray-400 font-main-bold mb-2"}>Detalii</h2>
                        <div className={"flex justify-center"}>
                            <div className={"text-gray-400"}>
                                <p className={"flex gap-3 items-center"}>
                                    * Fiecare credit reprezinta o pagina scanata
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={"rounded-lg shadow-lg border border-main-500 w-full bg-white p-5 text-center"}>
                        <p className={"text-main-500 mb-1"}>Recomandat</p>
                        <h1 className={"font-main-bold text-3xl"}>
                            Gold
                        </h1>
                        <p className={"text-center text-3xl font-main-bold mt-2"}>
                            <span>120€ </span><span className={"text-sm"}>+ TVA</span>
                        </p>
                        <div className={"mt-5 flex justify-center"}>
                            <div className={"w-fit h-fit"}>
                                <p className={"flex gap-3 font-main-medium items-center"}><FaCheck
                                    className={"text-main-500 mb-1"}/>
                                    600
                                    credite
                                </p>
                                <p className={"flex gap-3 items-center text-sm text-gray-500 justify-center"}>
                                    0,2 €/credit
                                </p>
                            </div>
                        </div>
                        <div className={"flex justify-center"}>
                            <div onClick={() => window.open(MainAppRouter.portalShop)}
                                 className={"font-main-bold cursor-pointer flex rounded-xl bg-main-500 w-fit px-6 py-3 mt-6 text-white"}>
                                Cumpara
                            </div>
                        </div>
                        <hr className={"my-4"}/>
                        <h2 className={"text-gray-400 font-main-bold mb-2"}>Detalii</h2>
                        <div className={"flex justify-center"}>
                            <div className={"text-gray-400"}>
                                <p className={"flex gap-3 items-center"}>
                                    * Fiecare credit reprezinta o pagina scanata
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className={"rounded-lg shadow-lg border border-gray-400 w-full bg-white p-5 text-center"}>
                        <p className={"text-transparent mb-1"}>Recomandat</p>
                        <h1 className={"font-main-bold text-3xl"}>
                            Platinum
                        </h1>
                        <p className={"text-center text-3xl font-main-bold mt-2"}>
                            <span>225 </span><span className={"text-sm"}>+ TVA</span>
                        </p>
                        <div className={"mt-5 flex justify-center"}>
                            <div className={"w-fit h-fit"}>
                                <p className={"flex gap-3 font-main-medium items-center"}><FaCheck
                                    className={"text-main-500 mb-1"}/>
                                    1500 credite
                                </p>
                                <p className={"flex gap-3 items-center text-sm text-gray-500 justify-center"}>
                                    0,15 €/credit
                                </p>
                            </div>
                        </div>
                        <div className={"flex justify-center"}>
                            <div onClick={() => window.open(MainAppRouter.portalShop)}
                                 className={"font-main-bold cursor-pointer flex rounded-xl bg-main-500 w-fit px-6 py-3 mt-6 text-white"}>
                                Cumpara
                            </div>
                        </div>
                        <hr className={"my-4"}/>
                        <h2 className={"text-gray-400 font-main-bold mb-2"}>Detalii</h2>
                        <div className={"flex justify-center"}>
                            <div className={"text-gray-400"}>
                                <p className={"flex gap-3 items-center"}>
                                    * Fiecare credit reprezinta o pagina scanata
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </div>
    );
}